<template>
  <!-- <v-container style="background-color:white"> -->
  <div class="container" style="margin-top: 30px; margin-bottom: 100px">
    <div class="d-flex justify-end">
      <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        <v-icon>{{
          $vuetify.theme.dark
            ? "mdi-moon-waning-crescent"
            : "mdi-white-balance-sunny"
        }}</v-icon>
      </v-btn>
    </div>
    <h3 style="color: #dd3e8d;font-weight:bold">
      Flex Vastgoed CRM
    </h3>

    <div style="margin-top:10px">
      <v-alert type="info">
        Welkom, Als er fouten zijn, kunt u een e-mail sturen naar
        <code> info@flexvastgoedcrm.nl</code>
      </v-alert>
    </div>

    <div style="padding: 1rem;"></div>

    <div class="row">
      <div class="col-12 col-lg-3 mb-3">
        <div>
          <div class="link-box">
            <img src="@/assets/data.png" alt="img" />
            <h2>12</h2>
            <p class="link-box-subtitle">Actie objecten</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mb-3">
        <div class="link-box">
          <img src="@/assets/data.png" alt="img" title="Huurders" />
          <h2>12</h2>
          <p class="link-box-subtitle">Inactieve objecten</p>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3">
        <div>
          <router-link class="router-link" :to="{}">
            <div class="link-box">
              <img src="@/assets/data.png" alt="img" />
              <h2>12</h2>
              <p class="link-box-subtitle">
                Leads
              </p>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3">
        <div>
          <router-link class="router-link" :to="{}">
            <div>
              <div class="link-box">
                <img src="@/assets/data.png" alt="img" />
                <h2>12</h2>
                <p class="link-box-subtitle">Projecten</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- </v-container> -->
</template>

<script>
// import axios from "axios";
import XLSX from "xlsx";
export default {
  data() {
    return {};
  },

  methods: {
    //download all houses
    downloadServices() {
      const data = XLSX.utils.json_to_sheet(this.allServices);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Alle Reperaties.xlsx");
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
