
<template>
  <Dashboard />
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Dashboard,
  },
  metaInfo: {
    title: "Welkom bij Flex vastgoed CRM | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>