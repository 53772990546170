<template>
  <div class="admin__main-container position-relative">
    <v-app-bar color="#373a43" dark>
      <v-btn icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        hide-details
        placeholder="Zoeken"
        class="mx-4"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon @click="goToProfile()">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-menu offset-y>
        <!-- <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>mdi-clock</v-icon>
            <v-badge v-if="isActive" color="red" content="1"> </v-badge>
          </v-btn>
        </template> -->
        <!-- <div class="timer">
          <div v-if="timers.length === 0" class="text-center">
            <v-row v-if="isActive">
              <b> Ingeklokt om:</b> {{ lastSheet }}
            </v-row>
            <v-row>
              <p class="text-center" v-if="isActive">
                <b>De tijd:</b> {{ formattedTime }}
              </p>
            </v-row>
            <v-row style="margin-left:5px; margin-bottom:5px">
              <v-btn
                class="mt-3"
                color="success"
                v-if="!isActive"
                @click="startTimer()"
              >
                Inklokken
              </v-btn>
            </v-row>
            <v-row style="margin-left:10px; margin-bottom:5px">
              <v-btn
                class="mt-3"
                color="error"
                v-if="isActive"
                small
                @click="stopTimer()"
                >Uitklokken</v-btn
              >
            </v-row>
          </div>
          <div class="text-center mt-3">
            <a
              href="https://perfexmodules.gtssolution.site/admin/staff/timesheets?view=all"
              >Bekijk alle registraties</a
            >
          </div>
        </div> -->
      </v-menu>

      <v-btn icon>
        <v-icon>mdi-format-list-checks</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>

    <SideBar
      :width="width"
      @toggle-collapse="collapsed = $event"
      :collapsed="collapsed"
    />
    <div class="content-container" :style="{ paddingLeft: containerPadding }">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from "../layouts/SideBar.vue";
//import apiTimeSheet from "@/components/api/apiTimeSheet";
export default {
  components: {
    SideBar,
  },
  data() {
    return {
     // lastSheet: [],
      time: new Date(),
      showTimers: false,
      isActive: "",
      timestamp: "",
      timers: [],
      width: 250,
      height: 0,
      collapsed: false,
      onMobile: false,
    };
  },
  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    // try {
    //   const data = await apiTimeSheet.hasActiveSheet();
    //   this.isActive = data;
    // } catch (error) {
    //   console.error(error);
    // }
    // try {
    //   const data = await apiTimeSheet.get();
    //   this.lastSheet = data;
    // } catch (error) {
    //   console.error(error);
    // }
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  },

  computed: {
    containerPadding() {
      return this.collapsed || this.onMobile ? "50px" : "250px";
    },
    formattedTime() {
      const options = {
        timeZone: "Europe/Amsterdam",
        hour12: false,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Intl.DateTimeFormat("nl-NL", options).format(this.time);
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.onMobile = true;
        this.collapsed = true;
      } else {
        this.onMobile = false;
        this.collapsed = false;
      }
    },
    observeNavHeight() {
      let observer = new ResizeObserver((entries) => {
        let entry = entries[0];
        this.height = entry.contentRect.height;
      });
      observer.observe(document.getElementById("myTopnav"), {
        box: "border-box",
      });
    },
    goToProfile() {
      this.$router.push("/Profile");
    },
    // async startTimer() {
    //   try {
    //     this.timestamp = new Date().toLocaleString("nl-NL");
    //     const data = {
    //       starttime: this.timestamp,
    //     };
    //     const response = await apiTimeSheet.clokIn(data);
    //     console.log(response);
    //     this.$swal.fire("Yes!", "Je bent ingeklokt!", "success");
    //     this.isActive = await apiTimeSheet.hasActiveSheet();
    //   } catch (error) {
    //     console.error(error);
    //     this.$swal.fire(
    //       "Er is een foutmelding!",
    //       "Probeer het nogmaals!",
    //       "error"
    //     );
    //   }
    // },

    // async stopTimer() {
    //   try {
    //     const data = {
    //       endtime: this.timestamp,
    //     };
    //     const response = await apiTimeSheet.clockOut(data);
    //     console.log(response);
    //     this.$swal.fire("Yes!", "U bent uitgeklokt!", "success");
    //     this.isActive = await apiTimeSheet.hasActiveSheet();
    //   } catch (error) {
    //     console.error(error);
    //     this.$swal.fire(
    //       "Er is een foutmelding!",
    //       "Probeer het nogmaals!",
    //       "error"
    //     );
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 100%;
}
.main-content {
  margin: 10px 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 30px;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  text-align: center; /* voeg deze regel toe */
}
</style>
