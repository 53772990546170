import {API} from "./api.base.js"

export default {
  async createConsult(data) {
    try {
      const response = await API.post('/api/rest/v2/consult',data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateConsult(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/consult/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getConsults() {
    try {
      const response = await API.get('/api/rest/v2/consult');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getConsultsbyId(id) {
    try {
      const response = await API.get(`/api/rest/v2/consult/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteConsultsbyId(id) {
    try {
      const response = await API.delete(`/api/rest/v2/consult/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async changeConsultsbyId() {
    try {
      const response = await API.get('/api/rest/v2/consult');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getMenuItems() {
    try {
      const response = await API.get("/api/rest/v2/menuitems");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
};
