<template>
  <sidebar-menu
    :menu="menu"
    :width="`${width}px`"
    @toggle-collapse="$emit('toggle-collapse', $event)"
    @item-click="onItemClick"
    :collapsed="collapsed"
  />
</template>

<script>
import eventBus from "@/components/api/eventBus";
import apiConsult from "../components/api/apiConsult";
import firebase from "firebase";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import SidebarLogoHeader from "../layouts/SidebarLogoHeader.vue";

export default {
  name: "Sidebar",
  props: ["width", "collapsed"],
  components: {
    SidebarMenu,
  },
  data() {
    return {
      menu: [
        {
          component: SidebarLogoHeader,
        },
        {
          header: "Main Navigation",
          hiddenOnCollapse: true,
        },
      ],
    };
  },
  created() {
    eventBus.$on("modules-updated", this.loadMenuItems);
  },
  mounted() {
    this.loadMenuItems();
  },
  methods: {
    async loadMenuItems() {
      try {
        const data = await apiConsult.getMenuItems();
        this.menu = [
          {
            component: SidebarLogoHeader,
          },
          {
            header: "Main Navigation",
            hiddenOnCollapse: true,
          },
          ...data,
          {
            title: "Uitloggen",
            icon: "fa fa-power-off",
          },
        ];
      } catch (error) {
        console.error("Failed to load menu items:", error);
        // Behandel de fout afhankelijk van je vereisten
      }
    },
    async logOut() {
      await firebase.auth().signOut();
      this.$router.push({ name: "/" });
    },
    onItemClick(event, item) {
      if (item.title === "Uitloggen") this.logOut();
    },
  },
};
</script>

<style>
.v-sidebar-menu .vsm--link {
  font-size: 14px !important;
  background-color: #373a43;
}

.custom-sidebar-menu .vsm--link {
  font-size: 14px !important;
  color: #fff; 
}

.v-sidebar-menu {
  background-color: #373a43;
}


.v-sidebar-menu .vsm--link.vsm--link_active {
  background-color: #147D81;
}

.v-sidebar-menu .vsm--item.vsm--item_open {
  background-color: #147D81;
}

.v-sidebar-menu .vsm--toggle-btn {
  background-color:#147D81;
}

.v-sidebar-menu .vsm--item {
  background-color:#147D81;
}
.v-sidebar-menu.vsm_rtl {
  background-color: #147D81;
}

.v-sidebar-menu .vsm--link.vsm--link_level-[n] {
  background-color:#147D81;
}

.v-sidebar-menu .vsm--mobile-item {
  background-color: #147D81;
}
.v-sidebar-menu .vsm--mobile-bg {
  background-color: #147D81;
}
.v-sidebar-menu .vsm--toggle-btn {
  background-color: #147D81;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
  background-color: #147D81;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color:#147D81;
}
</style>
