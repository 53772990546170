<template>
  <div class="d-flex justify-content-center px-5">
    <router-link to="Dashboard">
      <img
        src="@/assets/logo.png"
        alt="Logo"
        title="Flex vastgoed CRM"
        height="200"
        width="150"
        class="img-fluid"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLogoHeader",
};
</script>

<style></style>
